import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'

import {Alert, AlertTitle} from '@material-ui/core'
import { UserContext } from '../../../providers/UserProvider'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SaveIcon from '@material-ui/icons/Save'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import * as FirestoreService from '../../../firebase'
import { SingleDocDetail } from '../Docs'
import { APIHOST } from '../../../env'

const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
  ]

const Process = (props) => {
    const { processid } = useParams()
    const user = useContext(UserContext)
    const client = JSON.parse(localStorage.getItem('@keepdoc/client'))

    const [docType, setDocType] = useState({name:""})
    const [company, setCompany] = useState({reference:"",name:""})
    const [description, setDescription] = useState("")
    const [docs, setDocs] = useState([])
    const [process, setProcess] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(true)
    const [docsLoading, setDocsLoading] = useState(true)
    const [savingLoading, setSavingLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [snackbar, setSnackbar] = useState({
        active: false,
        text: "",
        severity: "error",
      })

    const handleChange = (event) => {
      var temporality = 0
      var expiring = 0
      setDocType({...docType, name: event.target.value})
  
      documentTypes.forEach(element => {
        if(element.name===event.target.value){
          temporality = element.temporality

          var date = new Date()
          date.setDate(date.getDate() + (365 * temporality))
          
          expiring = FirestoreService.Timestamp.fromDate(date)
          
          setDocType(element)
        }
      })
  
      setProcess({ ...process, documentType: event.target.value, temporality, expiring, metadata: [] })
    }

    const handleCompanyChange = (event) => {
      setCompany({reference: event.target.value})
      setProcess({ ...process, company: event.target.value })
    }

    const handleSubjectChange = (event, values) => {
      setProcess({ ...process, subject: values })
    }

    const handleSave = async () => {
  
      setSavingLoading(true)
  
      await FirestoreService.updateProcess(processid,process)
        .then(result => {
          setSnackbar({...snackbar, active: true, text: "Processo salvo com sucesso!", severity: 'success'})
          setSavingLoading(false)
        })
        .catch(error => {
          setSnackbar({...snackbar, active: true, text: "Ops! Não foi possível salvar o processo no momento.", severity: 'error'})
          setSavingLoading(false)
          console.log(error)
        })
  
      return true
  
    }

    const handleDeleteProcess = async () => {
      setDeleteLoading(true)
  
      const headers = {
        'Authorization': `Bearer ${user.token}`,
      }
      
      await axios.delete(`${APIHOST}/v1/documents?processid=${processid}`,{ headers: headers })
        .then(async function (response) {
          props.history.push(`/client/${client.id}`)
        })
        .catch(function (error) {
  
          console.log(error.response)
        
        })
  
    }

    useEffect(() => {

        props.setTitle("")
        props.setDescription("Aguarde...")

        FirestoreService.getDocumentTypesList()
          .then(result => {
    
            if (result.docs) {
              const resp = result.docs.map(doc => Object.assign({id: doc.id},doc.data()) )
    
              setDocumentTypes(resp)
            }
    
          })
  
    },[])

    useEffect(() => {
  
      FirestoreService.getProcessById(processid)
        .then(result => {
  
          if (result.exists) {
  
            setProcess({
              id: result.id,
              ...result.data()
            })

            const date = new Date(result.data().date.seconds * 1000)
  
            props.setTitle(`Processo # ${result.id}`)
            props.setDescription(date.toLocaleString())
  
            documentTypes.forEach(element => {
              if(element.name===result.data().documentType){
                setDocType(element)
              }
            })
  
            if(result.data().metadata&&result.data().metadata.description){
              setDescription(result.data().metadata.description)
            }
  
            if(client.group){
  
              client.group.forEach(element => {
                if(element.reference===result.data().company){
                  setCompany({reference: element.reference})
                }
              })
  
              setCompanies(client.group.map((company => { return company })))
            }
  
            setLoading(false)
  
          }
  
        })
  
    },[documentTypes]) 

    useEffect(() => {
  
      FirestoreService.getDocsByProcess(processid)
        .then(result => {
  
          if (result.docs) {
  
            const resp = result.docs.map(doc => Object.assign({id: doc.id},doc.data()) )
  
            setDocs(resp)
            setDocsLoading(false)
  
          }
  
        })
  
    },[process])

    const renderDocsData = () => {
      if(docs){
        return (
            <Stack spacing={3}>
                {docs.map((doc,index) => (
                <SingleDocDetail doc={doc} key={`doc${index}`} setSnackbar={setSnackbar} />
                ))}
            </Stack>
        )
      }else{
        return "Nenhum documento encontrado."
      }
    }

    const renderForm = () => {
        return (
        <Grid container spacing={3}>
            {client.group && <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="type"
                select
                label="Empresa"
                value={company.reference}
                onChange={handleCompanyChange}
                fullWidth
            >
                {companies.map((option, key) => (
                <MenuItem key={key} value={option.reference}>
                    {option.reference} - {option.name}
                </MenuItem>
                ))}
            </TextField>
            </Grid>}
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="type"
                select
                label="Tipo documental"
                value={docType.name}
                onChange={handleChange}
                fullWidth
            >
                {documentTypes.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                    {option.name}
                </MenuItem>
                ))}
            </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="title"
                label="Título"
                defaultValue={process.title || ""}
                helperText="Elemento de descrição que nomeia o documento."
                fullWidth
                onChange={e => setProcess({ ...process, title: e.target.value })}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
                multiple
                id="subject"
                options={top100Films.map((option) => option.title)}
                defaultValue={process.subject || []}
                freeSolo
                onChange={handleSubjectChange}
                renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
                }
                renderInput={(params) => (
                <TextField {...params} label="Assunto" placeholder="Assunto" helperText="Palavras-chave que representam o conteúdo do documento." />
                )}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
                id="location"
                label="Local da digitalização"
                defaultValue={process.location || ""}
                helperText="Ex: São Paulo - SP"
                onChange={e => setProcess({ ...process, location: e.target.value })}
                fullWidth
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
                id="author"
                label="Nome do autor"
                defaultValue={process.author || ""}
                helperText="Pessoa natural ou jurídica que emitiu o documento."
                fullWidth
                onChange={e => setProcess({ ...process, author: e.target.value })}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="responsible"
                label="Responsável pela digitalização"
                defaultValue={process.responsible || ""}
                helperText="Pessoa jurídica ou física responsável pela digitalização."
                fullWidth
                onChange={e => setProcess({ ...process, responsible: e.target.value })}
            />
            </Grid>
        </Grid>
        )
    }

    const getMetadataValue = (name) => {
  
      if (!process.metadata) return ""
  
      if (!process.metadata[name]) return ""
  
      return process.metadata[name]
  
    }

    const renderMetaData = () => {

        if(docType.metadata){
      
          return (
            <Grid container spacing={3} sx={{marginTop:"10px"}}>
    
              {docType.metadata.includes("competence") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="competence"
                  label="Competência"
                  defaultValue={getMetadataValue("competence")}
                  helperText="Ex: DD/MM"
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, competence: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("docDate") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="docDate"
                  label="Data do Documento"
                  defaultValue={getMetadataValue("docDate")}
                  helperText="Ex: DD/MM/AAAA"
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, docDate: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("dueDate") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="dueDate"
                  label="Data de Vencimento"
                  defaultValue={getMetadataValue("dueDate")}
                  helperText="Ex: DD/MM/AAAA"
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, dueDate: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("value") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="value"
                  label="Valor"
                  defaultValue={getMetadataValue("value")}
                  helperText="Valor monetário. Ex: R$ 500,00"
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, value: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("docClient") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="docClient"
                  label="CNPJ do Cliente"
                  defaultValue={getMetadataValue("docClient")}
                  helperText="Ex: 99.999.999/9999-99"
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, docClient: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("docProvider") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="docProvider"
                  label="CNPJ Prestador"
                  defaultValue={getMetadataValue("docProvider")}
                  helperText="Ex: 99.999.999/9999-99"
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, docProvider: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("docProvider2") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="docProvider2"
                  label="CNPJ Fornecedor"
                  defaultValue={getMetadataValue("docProvider2")}
                  helperText="Ex: 99.999.999/9999-99"
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, docProvider2: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("docTaker") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="docTaker"
                  label="CNPJ Tomador"
                  defaultValue={getMetadataValue("docTaker")}
                  helperText="Ex: 99.999.999/9999-99"
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, docTaker: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("colabName") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="colabName"
                  label="Nome do Colaborador"
                  defaultValue={getMetadataValue("colabName")}
                  fullWidth
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, colabName: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("colabDoc") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                  id="colabDoc"
                  label="CPF do Colaborador"
                  defaultValue={getMetadataValue("colabDoc")}
                  fullWidth
                  helperText="Ex: 999.999.999-99"
                  onChange={e => setProcess({ ...process, metadata: {...process.metadata, colabDoc: e.target.value} })}
                />
              </Grid>}
    
              {docType.metadata.includes("description") && <Grid item xs={12} sm={12} md={6}>
              <TextField
                select
                id="description"
                label="Descrição"
                defaultValue={getMetadataValue("description")}
                value={description}
                onChange={e => { setDescription(e.target.value); setProcess({ ...process, metadata: {...process.metadata, description: e.target.value} })}}
                fullWidth
              >
                {docType.description.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>}
    
              {docType.metadata.includes("debitAccount") && <Grid item xs={12} sm={12} md={6}>
                  <h6 style={{margin:"0"}}><b>Conta de débito</b></h6>
                  <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="debitAccountAg"
                    label="Agência"
                    defaultValue={getMetadataValue("debitAccountAg")}
                    helperText="Ex: XXXX"
                    fullWidth
                    onChange={e => setProcess({ ...process, metadata: {...process.metadata, debitAccountAg: e.target.value} })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="debitAccountNumber"
                    label="Conta"
                    defaultValue={getMetadataValue("debitAccountNumber")}
                    helperText="Ex: XXXXXX-X"
                    fullWidth
                    onChange={e => setProcess({ ...process, metadata: {...process.metadata, debitAccountNumber: e.target.value} })}
                  />
                </Grid>
                </Grid>
              </Grid>}
    
              {docType.metadata.includes("creditAccount") && <Grid item xs={12} sm={12} md={6}>
                  <h6 style={{margin:"0"}}><b>Conta de crédito</b></h6>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="creditAccountAg"
                      label="Agência"
                      defaultValue={getMetadataValue("creditAccountAg")}
                      helperText="Ex: XXXX"
                      fullWidth
                      onChange={e => setProcess({ ...process, metadata: {...process.metadata, creditAccountAg: e.target.value} })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="creditAccountNumber"
                      label="Conta"
                      defaultValue={getMetadataValue("creditAccountNumber")}
                      helperText="Ex: XXXXXX-X"
                      fullWidth
                      onChange={e => setProcess({ ...process, metadata: {...process.metadata, creditAccountNumber: e.target.value} })}
                    />
                  </Grid>
                </Grid>
    
              </Grid>}
    
            </Grid>
          )
        }else{
          return ""
        }
    
    }

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    {loading && <LinearProgress color="secondary" size={24} />}
                    {!loading && renderForm()}
                    {!loading && renderMetaData()}
                </Grid>
                <Grid item xs={12} md={4}>

                    {!loading && <Stack spacing={1}>
                        <Button variant="contained" size="large" fullWidth color="secondary" onClick={handleSave} startIcon={!savingLoading && <SaveIcon />}>
                            {savingLoading && <CircularProgress size={24} color="inherit" />}
                            {!savingLoading && 'Salvar'}
                        </Button>
                        <Button variant="outlined" size="large" fullWidth color="warning" onClick={() => { props.history.goBack() }} startIcon={<ArrowBackIcon />}>Voltar</Button>
                        <Button variant="outlined" size="large" fullWidth startIcon={!deleteLoading && <DeleteForeverIcon />} onClick={e=>{ setDeleteDialogOpen(true) }}>
                            {deleteLoading && <CircularProgress size={24} color="inherit" />}
                            {!deleteLoading && 'Excluir'}
                        </Button>
                    </Stack>}

                    {!docsLoading && <hr style={{margin:"20px 0", border: "1px solid #DDD"}} />}

                    {docsLoading && <Grid item xs={12}><LinearProgress size={24} /></Grid>}

                    {!docsLoading && renderDocsData()}

                </Grid>
            </Grid>

            <Snackbar
                anchorOrigin={{ vertical:'top', horizontal:'right' }}
                open={snackbar.active}
                autoHideDuration={6000}
                onClose={() => setSnackbar({...snackbar, active: false})}
            >
                <Alert onClose={() => setSnackbar({...snackbar, active: false})} severity={snackbar.severity}>
                {snackbar.text}
                </Alert>
            </Snackbar>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => { setDeleteDialogOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Tem certeza que deseja excluir este processo?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Os documentos e informações relacionados com este processo também serão excluídos.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => { setDeleteDialogOpen(false) }}>Cancelar</Button>
                <Button onClick={() => { handleDeleteProcess(); setDeleteDialogOpen(false) }} autoFocus>
                    Excluir
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Process)