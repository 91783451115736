import React, { useState, useEffect, useContext } from "react"
import prettyBytes from "pretty-bytes"
import axios from 'axios'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import DescriptionIcon from '@material-ui/icons/Description'
import ImageIcon from '@material-ui/icons/Image'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ScannerIcon from '@material-ui/icons/Scanner'
import AddAlertIcon from "@material-ui/icons/AddAlert"
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

import { UserContext } from '../../../providers/UserProvider'
import * as FirestoreService from '../../../firebase'
import { APIHOST } from '../../../env'

const noImageThumb = "https://firebasestorage.googleapis.com/v0/b/keepdoc-bcdcc.appspot.com/o/pdfThumb.png?alt=media&token=89cceabc-744b-444d-a63c-35940ebf24b7"

export function SingleDocDetail (props) {

    const user = useContext(UserContext)
    const data = props.doc
    const date = new Date(data.date.seconds * 1000)
    const [scanLoading, setScanLoading] = useState(false)
    const [doneScaning, setDoneScaning] = useState(false)

    const handleScan = async () => {
    
    setScanLoading(true)
    
    const headers = {
        'Authorization': `Bearer ${user.token}`,
    }
    
    await axios.patch(`${APIHOST}/v1/documents`,{
        filename: `${data.token}.${data.ext}`,
        user: data.user,
        token: data.token
    },{ headers: headers })
        .then(async function (response) {
            // console.log(response.data[0])

            await FirestoreService.updateDocument(data.id,{
            status: "DONE",
            detections: response.data[0]
            })
            .then(result => {

                setScanLoading(false)
                setDoneScaning(true)
                props.setSnackbar({active: true, text: "Scaneamento realizado com sucesso!", severity: 'success'})

            })
            .catch(error => {
                throw("Erro ao salvar no banco")
            })

        })
        .catch(function (error) {
            console.log(error)
            setScanLoading(false)
            props.setSnackbar({active: true, text: "Ops! Ocorreu um erro inesperado, tente novamente mais tarde.", severity: 'error'})
        })
    
    }

    return (
        <Card sx={{ maxWidth: "100%" }} key={data.id}>
            <CardMedia
                component="img"
                height="140"
                image={data.thumbUrl || noImageThumb}
                alt="green iguana"
                sx={{objectPosition:"top center"}}
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                {data.token}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {date.toLocaleString()}
                </Typography>
            </CardContent>
            <CardActions>
                <Stack spacing={1} direction="row">
                <Button size="small" onClick={e=>{window.open(`${data.url}`)}} startIcon={<OpenInNewIcon />}>Abrir</Button>
                {data.status === "PENDING"&&data.ext=== "pdf"&&!doneScaning && <Button
                    variant="contained"
                    component="label"
                    startIcon={!scanLoading && <DocumentScannerIcon />}
                    onClick={e=>{handleScan()}}
                >
                    {scanLoading && <CircularProgress size={24} color="inherit" />}
                    {!scanLoading && 'OCR'}
                </Button>}
                </Stack>
            </CardActions>
        </Card>
    )
}