import React from 'react';
import UserProvider from './providers/UserProvider';
import ThemesProvider from './providers/ThemeProvider'
import {Routes} from './routes/index';

export default function App(){

    return (
        <UserProvider>
            <ThemesProvider>
            <Routes />
            </ThemesProvider>
        </UserProvider>
    )
    
}