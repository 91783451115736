import React, { useState } from "react"
import {Container, CircularProgress, Button, Snackbar, TextField, Alert, Typography} from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";

import { auth } from '../../firebase'

import logo from '../../assets/img/icon.svg'

const useStyles = makeStyles({
  ForgetPasswordBox: {
      display: 'flex !important',
      height: '100vh',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '300px !important',
      margin: '0 auto',
      textAlign: 'center'
  },
})

const ForgetPassword = (props) => {
  const classes = useStyles()

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState(false)
  const [snackbartxt, setSnackbarTxt] = useState("")
  const [sended, setSended] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      recoverPassword()
    }
  }

  const recoverPassword = async () => {
    auth.sendPasswordResetEmail(email)
    .then(() => {
      setLoading(false); 
      setSended(true);
    })
    .catch((err) => {
      setLoading(false);
      setSnackbarTxt("Ocorreu um erro ao solicitar mudança de senha.")
      setSnackbar(true)
    });
  }
  
  return (

    <Container className={classes.ForgetPasswordBox}>
      
      <img src={logo} width='100' style={{marginBottom:'20px'}} alt="Keep Doc"></img>

      {sended ? (
        <>
        <Typography variant="h6" component="div" fontFamily="Roboto" gutterBottom sx={{ fontWeight: '400', marginBottom: '0' }}>Um link de redefinição de senha foi enviado para o seu e-mail.</Typography>
        <Button
          fullWidth={true}
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={() => props.history.push(`/`) }
          size="large"
          style={{marginTop:'8px'}}
        >
          Voltar para o login
        </Button>
        </>
      ) : (
        <>
        <Typography variant="h6" component="div" fontFamily="Roboto" gutterBottom sx={{ fontWeight: '400', marginBottom: '0' }}>Preencha o email associado à sua conta.</Typography>
        <TextField
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={e=>{ setEmail(e.target.value) }}
          onKeyDown={e=>{ handleKeyDown(e) } }
          color= "primary"
          fullWidth={true}
          margin="dense"
        />
        <Button
          fullWidth={true}
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={ _ => recoverPassword() }
          size="large"
          style={{marginTop:'8px'}}
        >
          {loading && <CircularProgress size={24} />}
          {!loading && 'Enviar'}
        </Button>
        
        <Snackbar
          anchorOrigin={{ vertical:'top', horizontal:'right' }}
          open={snackbar}
          autoHideDuration={6000}
          onClose={() => setSnackbar(false)}
        >
          <Alert onClose={() => setSnackbar(false)} severity="warning">
          {snackbartxt}
          </Alert>
        </Snackbar>
        </>
      )}
    </Container>

  )
  
}

export default ForgetPassword