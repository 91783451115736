import React, { Component } from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles'

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: "#d90c45",
            light: "#ff5670",
            dark: "#a0001f",
        },
        secondary: {
            main: "#a1d327",
            light: "#d6ff5f",
            dark: "#6ea200",
        },
        tertiary: {
            main: "#f3f5f9",
            light: "#ffffff",
            dark: "#c0c2c6",
        },
    }
})

class ThemesProvider extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                {this.props.children}
            </ThemeProvider>
        )
    }

}

export default ThemesProvider