import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {adminRoutes} from '../../../routes'
import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'
import * as FirestoreService from '../../../firebase'

import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';


const DocTypes = (props) => {
    const [docTypes, setDocTypes] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        FirestoreService.getDocumentTypesList()
          .then(docTypes => {
            if(docTypes.docs){
              const result = docTypes.docs.map(doc => Object.assign({id: doc.id,name: doc.data().name || "Sem nome", temporality: doc.data().temporality || ""}) )
    
              setLoading(false)
              setDocTypes(result)
            }
          })
  
        props.setTitle(adminRoutes[2].name)
        props.setDescription(adminRoutes[2].description)
  
    },[])

    const renderBody = () => {

        if(!loading){
    
          if(!docTypes){
    
            return (
              <Alert severity="info">Nenhum tipo documental cadastrado até o momento.</Alert>
            )
    
          }
    
          return (
    
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Temporalidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {docTypes.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.temporality}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
    
          )
    
        }else{
    
          return (
            <LinearProgress size={24} color="secondary" />
          )
    
        }
    
      }
    
      return renderBody()

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocTypes)