import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'
import _ from 'lodash';
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material'
import Select from '@material-ui/core/Select';
import FilterIcon from '@material-ui/icons/FilterList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import * as FirestoreService from '../../../firebase'
import { UserContext } from '../../../providers/UserProvider'
import {ALGOLIA_APP_ID, ALGOLIA_API_KEY} from '../../../env'

import algoliasearch from 'algoliasearch'

const searchClient = algoliasearch(ALGOLIA_APP_ID,ALGOLIA_API_KEY)
const indexProcesses = searchClient.initIndex('keepdoc.processes')
const indexDocs = searchClient.initIndex('keepdoc.docs')

const Search = (props) => {
    const { id } = useParams()
    const user = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [loadingSearch, setLoadingSearch] = useState(false)
    const [queriyng, setQueriyng] = useState(false)
    const [types, setTypes] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false)
    const [typeFilter, setTypeFilter] = useState("");
    const [searchField, setSearchField] = useState("");
    const [processSearch, setProcessSearch] = useState([]);
    const [docSearch, setDocSearch] = useState([]);
    const [searchProcesses, setSearchProcesses] = useState([]);
    const [dateInit, setDateInit] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [order, setOrder] = useState("newest");
    const [typesLoading, setTypesLoading] = useState(true);

    const handleSave = async () => {
  
    //   setSavingLoading(true)
  
    //   await FirestoreService.updateProcess(processid,process)
    //     .then(result => {
    //       setSnackbar({...snackbar, active: true, text: "Processo salvo com sucesso!", severity: 'success'})
    //       setSavingLoading(false)
    //     })
    //     .catch(error => {
    //       setSnackbar({...snackbar, active: true, text: "Ops! Não foi possível salvar o processo no momento.", severity: 'error'})
    //       setSavingLoading(false)
    //       console.log(error)
    //     })
  
    //   return true
  
    }

    useEffect(() => {

        props.setTitle("")
        props.setDescription("Aguarde...")

        FirestoreService.getClient(id)
        .then(async result => {

            if (result.exists) {

                props.setTitle(result.data().name || "Sem nome")
                props.setDescription(result.id)

                setLoading(false)

            }

        })
  
    },[])

    useEffect(() => {
        async function getTypes(){
            await axios.get('https://api.keepdoc.io/v1/documents/types', {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            })
            .then(response => {
                setTypesLoading(false);
                setTypes(response.data.data)
            })
            .catch(err => {
                setTypesLoading(false);
                console.log(err)
            })
        }

        getTypes()
    }, [])

    useEffect(() => {
        setLoadingSearch(true);
        setQueriyng(true)
        setDocSearch([]);
        setProcessSearch([]);
        setSearchProcesses([]);
        let count = 0;

        if(typeFilter !== "" || searchField !== ""){
            setLoadingSearch(true)
        } else {
            setLoadingSearch(false)
        }

        const hasDocumentType = typeFilter && typeFilter !== "";

        function filterDate(){

            const aux = dateInit.split('-')
            const aux2 = dateEnd.split('-')

            const init = new Date(Number(aux[0]), Number(aux[1] - 1), Number(aux[2]));
            const end = new Date(Number(aux2[0]), Number(aux2[1] - 1), Number(aux2[2]));

            if(dateInit.toString() !== '' && dateEnd.toString() !== ''){
                return `date >= ${init.getTime()} AND date <= ${end.getTime()}`;
            } else {
                if (dateInit.toString() !== ''){
                    return `date >= ${init.getTime()}`;
                } else if (dateEnd.toString() !== ''){
                    return `date <= ${end.getTime()}`
                } else {
                    return '';
                }
            }
        }
    
        const timer = setTimeout(async () => {
        if((typeFilter === "" || typeFilter === null) && searchField === ""){
            setSearchProcesses([]);
            return;
        } else {
            //BUSCA POR PROCESSOS
            await indexProcesses.search(searchField, {
                filters: filterDate(),
                facetFilters: hasDocumentType ? [
                    "client:" + id,
                    "documentType:" + typeFilter
                ] : ["client:" + id],
                hitsPerPage: 30,
            })
            .then(response => {
                count += response.hits.length;
                setProcessSearch(response.hits);
            })

            //BUSCA POR DOCS
            await indexDocs.search(searchField, {
                filters: filterDate(),
                facetFilters: ["user:" + id],
                hitsPerPage: 30
            })
            .then(response => {
                count += response.hits.length;
                setDocSearch(response.hits)
            })
        }

        if(count === 0){
            setLoadingSearch(false);
        }

        }, 1000)

        return () => clearTimeout(timer);
    }, [typeFilter, searchField, dateInit, dateEnd]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if((typeFilter === "" || typeFilter === null) && searchField === ""){
                setSearchProcesses([]);
                setLoadingSearch(false);
                return;
            } else {
                if(processSearch.length > 0 || docSearch.length > 0){
                    const totalProcesses = [...processSearch, ...docSearch]
                    const processes = _.uniqWith(totalProcesses, _.isEqual);

                    if(order === 'asc'){
                        const sortBy_az = processes.sort(function (a, b){
                            return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
                        });
                        setSearchProcesses(sortBy_az);
                        setLoadingSearch(false);
                    } else if (order === 'desc') {
                        const sortBy_za = processes.sort(function (a, b){
                            return (b.title > a.title) ? 1 : ((a.title > b.title) ? -1 : 0);
                        })
                        setSearchProcesses(sortBy_za);
                        setLoadingSearch(false);
                    } else if (order === 'older') {
                        const sortBy_older = processes.sort(function (a, b){
                            return a.date - b.date;
                        })
                        setSearchProcesses(sortBy_older);
                        setLoadingSearch(false);
                    } else if (order === 'newest') {
                        const newest = processes.sort(function (a, b){
                            return b.date - a.date;
                        })
                        setSearchProcesses(newest);
                        setLoadingSearch(false);
                    } else {
                        setSearchProcesses(processes);
                        setLoadingSearch(false);
                    }
                }
            }

        }, 1200);


        return () => clearTimeout(timer);
    }, [processSearch, docSearch, order]);

    const handleClick = (event) => {
        setFiltersVisible(!filtersVisible)
    }

    const renderBody = () => {

        if(!loading){

            if(!searchProcesses.length){

                return loadingSearch ? <LinearProgress size={24} color="secondary" /> : queriyng && <Alert severity="info">Nenhum documento encontrado para a pesquisa.</Alert>

            }

            return (

            <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                    <TableCell>Arquivo</TableCell>
                    <TableCell>Assunto</TableCell>
                    <TableCell>Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {searchProcesses.map((row) => {
                        const date = new Date(row.date)

                        return (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row"><Link href="#" variant="body2" onClick={(e) => { e.preventDefault(); props.history.push(`/process/${row.objectID}`) }}>{row.title || "SEM TÍTULO"}</Link><br />{row.id}</TableCell>
                                <TableCell>{row.subject &&
                                        
                                        row.subject.map((tag, key) => {
                                        return (<Chip label={tag} key={key} size="small" sx={{marginRight:"5px"}} />)
                                        })

                                }</TableCell>
                                <TableCell>{date.toLocaleString()}</TableCell>
                            </TableRow>
                        )
                    })}
                    {loadingSearch && <TableRow key="loading">
                        <TableCell colSpan={3}><LinearProgress size={24} color="secondary" /></TableCell>
                    </TableRow>}
                </TableBody>
                </Table>
            </TableContainer>
            </React.Fragment>
            )

        }else{

            return (
            <LinearProgress size={24} color="secondary" />
            )

        }

    }

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    {loading && <LinearProgress color="secondary" size={24} />}
                
                    <TextField
                        id="plan"
                        label="Digite o que procura aqui..."
                        onChange={e => setSearchField(e.target.value)}
                        fullWidth
                        sx={{marginBottom: '20px'}}
                    >
                    </TextField>

                </Grid>

                {!loading && (
                    <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                            <Button variant="outlined" size="large" fullWidth color="warning" onClick={() => { props.history.goBack() }} startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Stack>
                    </Grid>
                )}

            </Grid>

            {typesLoading ? (
                <LinearProgress color="secondary" size={24} sx={{marginBottom: '20px'}}/>
            ) : (
                <Grid item md={12}>
                    {types.length > 0 && (
                        typeFilter === "" ? (
                            <div style={{display: 'flex', marginBottom: '20px', overflowX: 'auto'}}>
                                {types.map(type => (
                                    <Button style={{marginRight: '10px', border: '2px #d90c45 solid', minWidth: 'unset'}} size="small" onClick={() => setTypeFilter(type.name)}>{type.name}</Button>
                                ))}
                            </div>
                        ) : (
                            <div style={{display: 'flex', marginBottom: '20px', overflowX: 'auto'}}>
                                <Button style={{margin: '0 8px', minWidth: '140px'}} size="small" variant="contained" onClick={() => {
                                    setTypeFilter("")
                                    setFiltersVisible(false);
                                }} endIcon={<CloseIcon />}>
                                {typeFilter}
                                </Button>

                                <IconButton aria-label="filter" size="small" onClick={handleClick}>
                                    <FilterIcon fontSize="small" />
                                </IconButton>
                            </div>
                        )
                    )}

                    {filtersVisible && (
                        <div style={{display: 'flex', marginBottom: '20px'}}>
                        <TextField label="Data Inicial" type="date" onChange={(e) => setDateInit(e.target.value)} value={dateInit} InputLabelProps={{ shrink: true }} style={{marginRight: '10px'}}/>
                        <TextField label="Data Final" type="date" onChange={(e) => setDateEnd(e.target.value)} value={dateEnd} InputLabelProps={{ shrink: true }}  style={{marginRight: '10px'}}/>
                        <Select value={order} onChange={(e) => setOrder(e.target.value)}>
                            <MenuItem value="newest">Mais recente primeiro</MenuItem>
                            <MenuItem value="older">Mais antigo primeiro</MenuItem>
                            <MenuItem value="asc">Nome de A-Z</MenuItem>
                            <MenuItem value="desc">Nome de Z-A</MenuItem>
                        </Select>
                        </div>
                    )}
                </Grid>
            )}

            <Grid container spacing={4}>
                <Grid item md={12}>
                    {renderBody()}
                </Grid>
            </Grid>

        </React.Fragment>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Search)