import React, { useContext, useEffect, useState } from 'react';
import { AppRoutes } from './app.routes';
import { AuthRoutes } from './auth.routes';
import { UserContext } from '../providers/UserProvider'
import { ClientRoutes } from './client.routes';

export const Routes = () => {
    const user = useContext(UserContext);
    const [isAdmin, setIsAdmin] = useState();

    useEffect(() => {
        if(user){
            if(user.name) setIsAdmin(true)
            else setIsAdmin(false)
        }
    }, [user])

    return user ? (isAdmin ? <AppRoutes /> : <ClientRoutes />) : <AuthRoutes />
}