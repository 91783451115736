import React from 'react';
import Client from "../layouts/Client";
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import storeConfig from '../store/storeConfig';

export const ClientRoutes = () => {
    return (
        <Provider store={storeConfig()}>
        <BrowserRouter>
            <Switch>
                <Route path="/" component={Client} />
            </Switch>
        </BrowserRouter>
        </Provider>
    )
}