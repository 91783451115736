import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {clientRoutes} from '../../../routes'
import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import {Alert} from '@material-ui/core'
import { UserContext } from '../../../providers/UserProvider'
import * as FirestoreService from '../../../firebase'

const ClientDashoard = (props) => {
    const user = useContext(UserContext);
    const account = user.uid;
    const [loadingPagination, setLoadingPagination] = useState(false)
    const [cursor, setCursor] = useState(null)
    const [hasEndPagination, setHasEndPagination] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [processes, setProcesses] = useState([]);

    useEffect(() => {
        props.setTitle(clientRoutes[0].name)
        props.setDescription(clientRoutes[0].description)
    },[])

    useEffect(() => {

        FirestoreService.getClient(account)
        .then(async result => {

            if (result.exists) {
                localStorage.setItem('@keepdoc/client', JSON.stringify({
                    id: result.id,
                    ...result.data()
                }))
            }
        })
  
    },[])

    const getClientProcesses = async (id) => {
        return await FirestoreService.getClientProcesses(id,cursor)
        .then(processesList => {
  
          setCursor(processesList.last)
    
          if(processesList.snapshot.docs){
    
            return processesList.snapshot.docs.map((doc) => {
              let data = doc.data()
              data.id = doc.id
    
              return data
            })
    
          }else{
              return []
          }
    
        })
    }

    useEffect(() => {
        async function fetchData() {

            setLoadingPagination(true)
            
            await getClientProcesses(account)
            .then(result => {
                // console.log(result.length)
                setLoadingPagination(false)
    
                if(!result.length){
                    setHasEndPagination(true)
                    return;
                }
    
                setProcesses([...processes, ...result])
            })

        }

        fetchData()
  
    }, [currentPage])

    const renderBody = () => {


            if(!processes.length){

                return loadingPagination ? <LinearProgress size={24} color="secondary" /> : <Alert severity="info">Nenhum arquivo enviado até o momento.</Alert>

            }

            return (

            <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                    <TableCell>Arquivo</TableCell>
                    <TableCell>Assunto</TableCell>
                    <TableCell>Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {processes.map((row) => {
                        const date = new Date(row.date.seconds * 1000)

                        return (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row"><Link href="#" variant="body2" onClick={(e) => { e.preventDefault(); props.history.push(`/client/process/${row.id}`) }}>{row.title || "SEM TÍTULO"}</Link><br />{row.id}</TableCell>
                                <TableCell>{row.subject &&
                                        
                                        row.subject.map((tag, key) => {
                                        return (<Chip label={tag} key={key} size="small" sx={{marginRight:"5px"}} />)
                                        })

                                }</TableCell>
                                <TableCell>{date.toLocaleString()}</TableCell>
                            </TableRow>
                        )
                    })}
                    {loadingPagination && <TableRow key="loading">
                        <TableCell colSpan={3}><LinearProgress size={24} color="secondary" /></TableCell>
                    </TableRow>}
                </TableBody>
                </Table>
            </TableContainer>
            <Stack spacing={2} direction="row" sx={{justifyContent:"center", marginTop: '20px'}}>
                {hasEndPagination  && <Alert variant="outlined" severity="success">Todos os arquivos estão listados.</Alert>}
                {!hasEndPagination  && <Button variant="text" onClick={() => { setCurrentPage((no) => no + 1) }}>Carregar mais arquivos</Button>}
            </Stack>
            </React.Fragment>
            )

        

    }

    return (
        <React.Fragment>
            {renderBody()}
        </React.Fragment>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientDashoard)