import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'

import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'

import SaveIcon from '@material-ui/icons/Save'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import * as FirestoreService from '../../../firebase'
import { UserContext } from '../../../providers/UserProvider'

const ClientEdit = (props) => {
    const { id } = useParams()
    const user = useContext(UserContext)

    const [client, setClient] = useState([])
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(true)
    const [savingLoading, setSavingLoading] = useState(false)
    const [snackbar, setSnackbar] = useState({
        active: false,
        text: "",
        severity: "error",
      })

    const handleGroupChange = (event, values) => {

        // values = values.map()

        setClient({ ...client, group: values })

    }

    const handleSave = async () => {
  
    //   setSavingLoading(true)
  
    //   await FirestoreService.updateProcess(processid,process)
    //     .then(result => {
    //       setSnackbar({...snackbar, active: true, text: "Processo salvo com sucesso!", severity: 'success'})
    //       setSavingLoading(false)
    //     })
    //     .catch(error => {
    //       setSnackbar({...snackbar, active: true, text: "Ops! Não foi possível salvar o processo no momento.", severity: 'error'})
    //       setSavingLoading(false)
    //       console.log(error)
    //     })
  
    //   return true
  
    }

    useEffect(() => {

        props.setTitle("")
        props.setDescription("Aguarde...")

        FirestoreService.getClient(id)
        .then(async result => {

            if (result.exists) {
                let group = null

                if(result.data().group){
                    group = result.data().group.map((item) => {
                        return `${item.reference} - ${item.name}`
                    })
                }

                setClient({
                    id: result.id,
                    reference: result.data().reference,
                    name: result.data().name,
                    plan: result.data().plan,
                    group,
                    })

                props.setTitle(result.data().name || "Sem nome")
                props.setDescription(result.id)

                setLoading(false)

            }

        })
  
    },[])

    const renderForm = () => {
        return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="name"
                label="Nome de exibição"
                defaultValue={client.name}
                helperText="Este campo representa o nome de exibição para o cliente."
                fullWidth
                onChange={e => setClient({ ...client, name: e.target.value })}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="plan"
                select
                label="Plano contratado"
                value={client.plan}
                onChange={e => setClient({ ...client, plan: e.target.value })}
                fullWidth
            >
                <MenuItem key='basic' value='basic'>
                    Básico (Gratuito)
                </MenuItem>
            </TextField>
            </Grid>
            {client.group && 
            <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
                multiple
                id="group"
                defaultValue={client.group || []}
                freeSolo
                onChange={handleGroupChange}
                renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
                }
                renderInput={(params) => (
                <TextField {...params} label="Empresas agrupadas" placeholder="Empresas agrupadas" helperText="Informe o número referência mais o nome da empresa separados por um hífen (-)." />
                )}
            />
            </Grid>}
        </Grid>
        )
    }

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    {loading && <LinearProgress color="secondary" size={24} />}
                    {!loading && renderForm()}
                </Grid>
                <Grid item xs={12} md={4}>

                    {!loading && <Stack spacing={1}>
                        <Button variant="contained" size="large" disabled fullWidth color="secondary" onClick={handleSave} startIcon={!savingLoading && <SaveIcon />}>
                            {savingLoading && <CircularProgress size={24} color="inherit" />}
                            {!savingLoading && 'Salvar'}
                        </Button>
                        <Button variant="outlined" size="large" fullWidth color="warning" onClick={() => { props.history.goBack() }} startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Stack>}

                </Grid>
            </Grid>

            <Snackbar
                anchorOrigin={{ vertical:'top', horizontal:'right' }}
                open={snackbar.active}
                autoHideDuration={6000}
                onClose={() => setSnackbar({...snackbar, active: false})}
            >
                <Alert onClose={() => setSnackbar({...snackbar, active: false})} severity={snackbar.severity}>
                {snackbar.text}
                </Alert>
            </Snackbar>

        </React.Fragment>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientEdit)