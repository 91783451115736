import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'
import * as FirestoreService from '../../../firebase'

const actions = [
    { icon: <CloudUploadIcon />, name: 'Enviar', operation: 'post' },
    { icon: <EditIcon />, name: 'Editar Cliente', operation: 'edit' },
    { icon: <SearchIcon />, name: 'Pesquisar', operation: 'search' },
  ];

const Clients = (props) => {
    const { id } = useParams()
    const [processes, setProcesses] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingPagination, setLoadingPagination] = useState(false)
    const [cursor, setCursor] = useState(null)
    const [hasEndPagination, setHasEndPagination] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const handleClick  = (e,operation) => {
        e.preventDefault()

        switch (operation) {
            case 'post':
                props.history.push(`/post/${id}`)
                break;
            case 'edit':
                props.history.push(`/clientEdit/${id}`)
                break;
            case 'search':
                props.history.push(`/search/${id}`)
                break;
        }
    }

    const getClientProcesses = async (id) => {
  
      return await FirestoreService.getClientProcesses(id,cursor)
      .then(processesList => {

        setCursor(processesList.last)
  
        if(processesList.snapshot.docs){
  
          return processesList.snapshot.docs.map((doc) => {
            let data = doc.data()
            data.id = doc.id
  
            return data
          })
  
        }else{
            return []
        }
  
      })
  
    }

    useEffect(() => {

        props.setTitle("")
        props.setDescription("Aguarde...")

        FirestoreService.getClient(id)
        .then(async result => {

            if (result.exists) {

            // setClient({
            //     id: result.id,
            //     ...result.data()
            // })

            props.setTitle(result.data().name || "Sem nome")
            props.setDescription(result.id)

            localStorage.setItem('@keepdoc/client', JSON.stringify({
                id: result.id,
                ...result.data()
            }))

            setLoading(false)

            }

            // console.log(localStorage.getItem('@keepdoc/client'))

        })
  
    },[])

    useEffect(() => {
        async function fetchData() {

            setLoadingPagination(true)
            
            await getClientProcesses(id)
            .then(result => {
                // console.log(result.length)
                setLoadingPagination(false)
    
                if(!result.length){
                    setHasEndPagination(true)
                    return;
                }
    
                setProcesses([...processes, ...result])
            })

        }

        fetchData()
  
    },[currentPage])


    const renderBody = () => {

        if(!loading){

            if(!processes.length){

                return loadingPagination ? <LinearProgress size={24} color="secondary" /> : <Alert severity="info">Nenhum arquivo enviado até o momento.</Alert>

            }

            return (

            <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                    <TableCell>Arquivo</TableCell>
                    <TableCell>Assunto</TableCell>
                    <TableCell>Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {processes.map((row) => {
                        const date = new Date(row.date.seconds * 1000)

                        return (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row"><Link href="#" variant="body2" onClick={(e) => { e.preventDefault(); props.history.push(`/process/${row.id}`) }}>{row.title || "SEM TÍTULO"}</Link><br />{row.id}</TableCell>
                                <TableCell>{row.subject &&
                                        
                                        row.subject.map((tag, key) => {
                                        return (<Chip label={tag} key={key} size="small" sx={{marginRight:"5px"}} />)
                                        })

                                }</TableCell>
                                <TableCell>{date.toLocaleString()}</TableCell>
                            </TableRow>
                        )
                    })}
                    {loadingPagination && <TableRow key="loading">
                        <TableCell colSpan={3}><LinearProgress size={24} color="secondary" /></TableCell>
                    </TableRow>}
                </TableBody>
                </Table>
            </TableContainer>
            <Stack spacing={2} direction="row" sx={{justifyContent:"center", marginTop: '20px'}}>
                {hasEndPagination  && <Alert variant="outlined" severity="success">Todos os arquivos estão listados.</Alert>}
                {!hasEndPagination  && <Button variant="text" onClick={() => { setCurrentPage((no) => no + 1) }}>Carregar mais arquivos</Button>}
            </Stack>
            </React.Fragment>
            )

        }else{

            return (
            <LinearProgress size={24} color="secondary" />
            )

        }

    }

    return (
        <React.Fragment>
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', top: 100, right: 26 }}
            icon={<SpeedDialIcon />}
            direction="down"
            FabProps={{color:"secondary"}}
        >
            {actions.map((action) => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={(e) => { handleClick(e,action.operation) }}
            />
            ))}
        </SpeedDial>

        {renderBody()}
        </React.Fragment>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Clients)