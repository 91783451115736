import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Box from '@mui/material/Box';
import {Grid, Typography} from '@mui/material'
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@material-ui/icons/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import Logo from '../assets/img/logo-light.svg'

import {adminRoutes} from '../routes'
import { auth } from '../firebase'
import { mapStateToProps, mapDispatchToProps } from '../store/functions/header'

const switchRoutes = (
  <Switch>
    {adminRoutes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
  </Switch>
);

function Admin(props) {
    const { title, description } = props
    const [value, setValue] = React.useState(1)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        props.history.push('/');
        auth.signOut();
    }

    useEffect(() => {
        props.setTitle(adminRoutes[value].name)
        props.setDescription(adminRoutes[value].description)
        props.history.push(`${adminRoutes[value].path}`)
    }, [value])

    return (
        <React.Fragment>
            <Box sx={{ bgcolor: 'primary.main', padding: '20px', position: 'relative' }}>
            <Grid container spacing={4}>
                <Grid item xs={8}>
                    <img src={Logo} width={100} alt="Keep Doc" />
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                    <Button
                        variant="text"
                        color="tertiary"
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <PersonIcon />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => logout()}>Sair</MenuItem>
                    </Menu>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: '700', color: '#FFF', marginBottom: '0' }}>
                        {/* {routes[value].name} */}
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom component="div" sx={{ color: '#FFF', fontWeight: '300' }}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
            </Box>
            <Box sx={{ bgcolor: 'primary.main' }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box sx={{ bgcolor: 'tertiary.main', padding: '20px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                </Grid>
            </Grid>
            </Box>

            <Box sx={{ margin: '0 30px 100px 30px'}}>
            {switchRoutes}
            </Box>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={1}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                >

                    {adminRoutes.map((prop, key) => {
                        if(!prop.show){
                            return null
                        }

                        return (
                            // <div>
                            // <NavLink to={prop.path} key={key}>{prop.name}</NavLink>
                            <BottomNavigationAction label={prop.name} icon={prop.icon} key={key} />
                            // </div>
                        )
                    })}

                </BottomNavigation>
            </Paper>
        </React.Fragment>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin)