import {
    HEADER_TITLE_CHANGED,
    HEADER_DESC_CHANGED
} from './actionsTypes'

export function updateHeaderTitle(newTitle) {
    return {
        type: HEADER_TITLE_CHANGED,
        payload: newTitle,
    }
}

export function updateHeaderDesc(newDescription) {
    return {
        type: HEADER_DESC_CHANGED,
        payload: newDescription,
    }
}