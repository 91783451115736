import React from 'react';
import SignIn from "../views/SignIn";
import ForgetPassword from '../views/ForgetPassword';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

export const AuthRoutes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={SignIn} />
                <Route path="/forget-password" component={ForgetPassword} />
            </Switch>
        </BrowserRouter>
    )
}