import React, { useState } from "react"
import {Container, CircularProgress, Button, Snackbar, TextField, Alert} from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";

import { auth } from '../../firebase'

import logo from '../../assets/img/icon.svg'

const useStyles = makeStyles({
  SignInBox: {
      display: 'flex !important',
      height: '100vh',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '300px !important',
      margin: '0 auto',
  },
})

const SignIn = (props) => {
  const classes = useStyles()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState(false)
  const [snackbartxt, setSnackbarTxt] = useState("")

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      signInWithEmailAndPasswordHandler()
    }
  }

  const signInWithEmailAndPasswordHandler = async () => {
    setSnackbar(false)

    if(!email){
      setSnackbarTxt("Preencha o e-mail para entrar.")
      setSnackbar(true)
      return false
    }

    if(!password){
      setSnackbarTxt("Informe sua senha para entrar.")
      setSnackbar(true)
      return false
    }

    if (!loading) {
      setLoading(true)
    }

    await auth.signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      setSnackbarTxt("E-mail ou senha incorretos!")
      setSnackbar(true)
      setLoading(false)
      setPassword("")
    })
    
  }
  
  return (

    <Container className={classes.SignInBox}>
      
      <img src={logo} width='100' style={{marginBottom:'20px'}} alt="Keep Doc"></img>
      <TextField
        label="E-mail"
        variant="outlined"
        value={email}
        onChange={e=>{ setEmail(e.target.value) }}
        onKeyDown={e=>{ handleKeyDown(e) } }
        color= "primary"
        fullWidth={true}
        margin="dense"
      />
      <TextField
        label="Senha"
        variant="outlined"
        value={password}
        type="password"
        onChange={e=>{ setPassword(e.target.value) }}
        onKeyDown={e=>{ handleKeyDown(e) } }
        color= "primary"
        fullWidth={true}
        margin="dense"
        sx={{ borderColor: 'secondary.main', borderWidth: '2' }}
      />
      <Button
        fullWidth={true}
        color="primary"
        variant="contained"
        disabled={loading}
        onClick={ _ => signInWithEmailAndPasswordHandler() }
        size="large"
        style={{marginTop:'8px'}}
      >
        {loading && <CircularProgress size={24} />}
        {!loading && 'Entrar'}
      </Button>

      <Button
        fullWidth={true}
        color="primary"
        onClick={() => props.history.push(`/forget-password`) }
        size="large"
        style={{marginTop:'8px'}}
      >
        Esqueceu sua senha?
      </Button>
      
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
      >
        <Alert onClose={() => setSnackbar(false)} severity="warning">
        {snackbartxt}
        </Alert>
      </Snackbar>
      
    </Container>

  )
  
}

export default SignIn