import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import prettyBytes from "pretty-bytes"
import axios from 'axios'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Alert from '@mui/material/Alert'
import LinearProgress from '@mui/material/LinearProgress'

import { UserContext } from '../../../providers/UserProvider'
import * as FirestoreService from '../../../firebase'
import { APIHOST } from '../../../env'

const fileToBase64 = (file) => {

    return new Promise(resolve => {
      var reader = new FileReader()
  
      reader.onload = (e) => {
        resolve(e.target.result.split(',')[1])
      }
  
      reader.readAsDataURL(file)
    })
  
  }

export default function SingleFileUploadWithProgress (props){

    const [loading, setLoading] = useState(true)
    const [processId, setProcessId] = useState(uuidv4())
    const [alert, setAlert] = useState({
      active: false,
      text: "",
      severity: "error"
    })

    var ext = ""
    const user = useContext(UserContext)

    useEffect(() => {

        switch (props.file.type) {
            case "image/jpeg":
            case "image/jpg":
              ext = "jpg"
              break;
            case "application/pdf":
              ext = "pdf"
              break;
            default:
    
                setAlert({
                    active: true,
                    text: "Tipo de arquivo não permitido para envio.",
                    severity: "error"
                  })
                  setLoading(false)
                  return false
    
                break;
          }

        if(!alert.active){

            fileToBase64(props.file)
                .then(async result => {
    
                const headers = {
                    'Authorization': `Bearer ${user.token}`,
                }
    
                await axios.post(`${APIHOST}/v1/documents`, {
                    processid: processId,
                    uid: props.client.id,
                    ext,
                    filename: props.file.name,
                    base64: result,
                }, { headers: headers })
                .then(async function (response) {
                    // console.log(response.data);
                    // console.log(processId)
    
                    await FirestoreService.saveProcess(processId,{
                        ...props.process,
                        client: props.client.id,
                        status:'PENDING',
                        date: FirestoreService.fieldValue.serverTimestamp()
                    })

                    setLoading(false)
                    setAlert({
                        active: true,
                        text: "Documento enviado com sucesso!",
                        severity: "success"
                      })
                })
                .catch(function (error) {
                    var errorMsg = "Ops! Ocorreu um erro inesperado. Tente novamente mais tarde."
    
                    console.log(error.response)
    
                    if(error.response&&error.response.status){
                    switch (error.response.status) {
                        case 422:
                        errorMsg = "Ops! O arquivo selecionado não possui texto para leitura."
                        break;
                    
                        default:
                        errorMsg = "Ops! Ocorreu um erro inesperado. Tente novamente mais tarde."
                        break;
                    }
                    }
    
                    setLoading(false)
                    setAlert({
                        active: true,
                        text: errorMsg,
                        severity: "error"
                      })
                });
                
                })

        }

    },[])

    return (
        <TableRow key={processId}>
            <TableCell component="th" scope="row" sx={{padding:'10px'}}>
            <strong>{props.file.name}</strong> {prettyBytes(props.file.size)}
            <div style={{marginTop:'10px'}}>
                { loading && <LinearProgress color="secondary" sx={{marginBottom:'10px'}} /> }{ alert.active && <Alert severity={alert.severity}>{alert.text}</Alert> }
            </div>
            </TableCell>
        </TableRow>
    )

}