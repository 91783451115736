import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import {adminRoutes} from '../../../routes'
import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'

import {Alert, AlertTitle} from '@material-ui/core'

const Dashboard = (props) => {

    useEffect(() => {
  
        props.setTitle(adminRoutes[0].name)
        props.setDescription(adminRoutes[0].description)
  
    },[])

    return (
        <Alert
            severity="info"
        >
            <AlertTitle>Em construção</AlertTitle>
            No momento o dashboard do Keep Doc está em construção.
        </Alert>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)