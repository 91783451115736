import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'

import {Alert} from '@material-ui/core'
import { UserContext } from '../../../providers/UserProvider'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import * as FirestoreService from '../../../firebase'
import SingleFileUploadWithProgress from '../Uploads'

const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
  ]

const Post = (props) => {
    const { id } = useParams()
    const client = JSON.parse(localStorage.getItem('@keepdoc/client'))

    const [files, setFiles] = useState([])
    const [process, setProcess] = useState({
      location: "AV. BARREIRA GRANDE, Nº 1343, VILA BANCÁRIA - SÃO PAULO",
      author: "PARA VOCE CONTAR CONSULTORIA EMPRESARIAL LTDA",
      responsible: "PARA VOCE CONTAR CONSULTORIA EMPRESARIAL LTDA",
    })
    const [documentTypes, setDocumentTypes] = useState([])
    const [companies, setCompanies] = useState([])
    const [savingLoading, setSavingLoading] = useState(false)

    const handleChange = (event) => {
      var temporality = 0
      var expiring = 0

      documentTypes.forEach(element => {
        if(element.name==event.target.value){
          temporality = element.temporality

          var date = new Date()
          date.setDate(date.getDate() + (365 * temporality))

          expiring = FirestoreService.Timestamp.fromDate(date)
        }
      });

      setProcess({ ...process, documentType: event.target.value, temporality, expiring, metadata: [] })
    }

    const handleCompanyChange = (event) => {
      setProcess({ ...process, company: event.target.value })
    }

    const handleSubjectChange = (event, values) => {
      setProcess({ ...process, subject: values })
    }

    const handleFilesUpload = (uploadedFiles,e) => {

      console.log(process)
  
      setFiles([...files, ...Array.from(uploadedFiles)])
      e.target.value = null
  
    }

    useEffect(() => {

      props.setTitle("")
      props.setDescription("Aguarde...")

      FirestoreService.getClient(id)
      .then(async result => {

          if (result.exists) {

          props.setTitle(result.data().name || "Sem nome")
          props.setDescription(result.id)

          }

      })

      FirestoreService.getDocumentTypesList()
        .then(result => {
  
          if (result.docs) {
            const resp = result.docs.map(doc => Object.assign({id: doc.id},doc.data()) )
  
            setDocumentTypes(resp)
          }
  
        })

        console.log(client)

      if(client.group){
        setCompanies(client.group.map((company => { return company })))
      }
  
    },[])

    const renderForm = () => {
        return (
        <Grid container spacing={3}>
            {client.group && <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="type"
                select
                label="Empresa"
                onChange={handleCompanyChange}
                fullWidth
            >
                {companies.map((option, key) => (
                <MenuItem key={key} value={option.reference}>
                    {option.reference} - {option.name}
                </MenuItem>
                ))}
            </TextField>
            </Grid>}
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="type"
                select
                label="Tipo documental"
                onChange={handleChange}
                fullWidth
            >
                {documentTypes.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                    {option.name}
                </MenuItem>
                ))}
            </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="title"
                label="Título padrão"
                helperText="Este título será aplicado a todos os documentos enviados nesta sessão."
                fullWidth
                onChange={e => setProcess({ ...process, title: e.target.value })}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
                multiple
                id="subject"
                options={top100Films.map((option) => option.title)}
                freeSolo
                onChange={handleSubjectChange}
                renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
                }
                renderInput={(params) => (
                <TextField {...params} label="Assunto" placeholder="Assunto" helperText="Palavras-chave que representam o conteúdo do documento." />
                )}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
                id="location"
                label="Local da digitalização"
                value={process.location}
                helperText="Ex: São Paulo - SP"
                onChange={e => setProcess({ ...process, location: e.target.value })}
                fullWidth
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
                id="author"
                label="Nome do autor"
                value={process.author}
                helperText="Pessoa natural ou jurídica que emitiu o documento."
                fullWidth
                onChange={e => setProcess({ ...process, author: e.target.value })}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id="responsible"
                label="Responsável pela digitalização"
                value={process.responsible}
                helperText="Pessoa jurídica ou física responsável pela digitalização."
                fullWidth
                onChange={e => setProcess({ ...process, responsible: e.target.value })}
            />
            </Grid>
        </Grid>
        )
    }

    const renderFiles = () => {

      if(files.length){
        return (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {files.map((fileWrapper,index) => (
                  <SingleFileUploadWithProgress file={fileWrapper} client={client} key={index} process={process} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )      
      }
  
    }

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Alert severity="warning" sx={{marginBottom:'20px'}}>
                      Os arquivos enviados nesta página irão herdar os valores contidos nos campos abaixo.
                    </Alert>
                    {renderForm()}
                </Grid>
                <Grid item xs={12} md={4}>

                    <Stack spacing={1}>
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" size="large" fullWidth startIcon={!savingLoading && <UploadFileIcon />} component="span">
                          <input
                            id="contained-button-file"
                            type="file"
                            hidden
                            multiple
                            onChange={(e) => { handleFilesUpload(e.target.files,e) }}
                          />
                            {savingLoading && <CircularProgress size={24} color="inherit" />}
                            {!savingLoading && 'Selecionar Arquivo'}
                        </Button>


                      </label>
                        <Button variant="outlined" size="large" fullWidth color="warning" onClick={() => { props.history.goBack() }} startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Stack>

                    <hr style={{margin:"20px 0", border: "1px solid #DDD"}} />

                    {renderFiles()}

                </Grid>
            </Grid>
        </React.Fragment>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Post)