import { createStore, combineReducers } from 'redux'

import headerReducer from './reducers/header'

const reducers = combineReducers({
    header: headerReducer,
})

function storeConfig() {
    return createStore(reducers)
}

export default storeConfig