import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../store/functions/header'
import { UserContext } from '../../providers/UserProvider'
import { Avatar, Divider, Stack, Typography } from '@material-ui/core'
import { firestore } from '../../firebase'

const Profile = () => {
    const user = useContext(UserContext);
    const [totalFiles, setTotalFiles] = useState(0);

    function formatName(){
        if(user.displayName){
            const aux = user.displayName.split(' ');
            const firstLetter = aux[0].substr(0, 1);
            const lastLetter = aux[aux.length - 1].substr(0, 1);
            return(firstLetter+lastLetter);
        } else {
            const aux = user.email.split('@');
            const firstLetter = aux[0].substr(0, 1).toUpperCase();
            return(firstLetter);
        }
    }

    useEffect(()  => {
        if(!user.name){
            firestore.collection('docs')
            .where('user', '==', user.uid)
            .get()
            .then(response => {
                let files = 0;
                response.docs.map(doc => files++);
                files = response.docs.length;
                return {files};
            })
            .then(({files}) => setTotalFiles(files))
        }
    }, []);

    return (
        <React.Fragment>
            <Stack direction="column" divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {user.photoURL ? <Avatar sx={{ width: 60, height: 60 }} alt={user.displayName} src={user.photoURL} /> : <Avatar sx={{ bgcolor: "#A1D327", width: 60, height: 60, fontSize: 20 }}>{formatName()}</Avatar>}
                    <div style={{display: 'flex', flexDirection:  'column', marginLeft: 20}}>
                        <Typography variant="h6" component="div" gutterBottom fontFamily="Roboto" sx={{ fontWeight: '700', marginBottom: '0' }}>{user.displayName ? user.displayName : user.email.split('@')[0]}</Typography>
                        <Typography variant="h7" component="div" gutterBottom fontFamily="Roboto" sx={{ fontWeight: '400', marginBottom: '0' }}>{user.email}</Typography>
                    </div>
                </div>

                {!user.name && (
                    <div>
                        <Typography variant="h6" component="div" gutterBottom fontFamily="Roboto" sx={{ fontWeight: '700', marginBottom: '10px' }}>Detalhes</Typography>
                        <Typography variant="h7" component="div" gutterBottom fontFamily="Roboto" sx={{ fontWeight: '400', marginBottom: '5px' }}>Plano gratuito</Typography> 
                        <Typography variant="h7" component="div" gutterBottom fontFamily="Roboto" sx={{ fontWeight: '400', marginBottom: '10px' }}>{totalFiles} documentos enviados e avaliados.</Typography> 
                    </div>
                )}

            </Stack>
        </React.Fragment>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile)