import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SearchIcon from '@material-ui/icons/Search'
import ListIcon from '@material-ui/icons/List';
import UserIcon from '@material-ui/icons/Person';

import Clients from './views/Admin/Clients'
import Client from './views/Admin/Client'
import ClientEdit from './views/Admin/ClientEdit'
import Search from './views/Admin/Search'
import Process from './views/Admin/Process'
import DocTypes from './views/Admin/DocTypes'
import Dashboard from './views/Admin/Dashboard'
import Post from './views/Admin/Post'

import ClientDashboard from './views/Client/Dashboard'
import ClientSearch from './views/Client/Search'
import Profile from './views/Profile'

export const adminRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        description: "",
        icon: <DashboardIcon />,
        component: Dashboard,
        layout: "/admin",
        show: true,
    },
    {
        path: "/clients",
        name: "Clientes",
        description: "Selecione o cliente para gerenciar documentos ou enviar novos arquivos.",
        icon: <PeopleAltIcon />,
        component: Clients,
        layout: "/admin",
        show: true,
    },
    {
        path: "/doctypes",
        name: "Tipos documentais",
        description: "Confira abaixo a lista dos tipos documentais disponíveis no Keep Doc",
        icon: <ListIcon />,
        component: DocTypes,
        layout: "/admin",
        show: true,
    },
    {
        path: "/profile",
        name: "Meu perfil",
        description: "",
        icon: <UserIcon />,
        component: Profile,
        layout: "/admin",
        show: true,
    },
    {
        path: "/client/:id",
        component: Client,
        layout: "/admin",
        show: false,
    },
    {
        path: "/clientEdit/:id",
        component: ClientEdit,
        layout: "/admin",
        show: false,
    },
    {
        path: "/search/:id",
        component: Search,
        layout: "/admin",
        show: false,
    },
    {
        path: "/post/:id",
        component: Post,
        layout: "/admin",
        show: false,
    },
    {
        path: "/process/:processid",
        component: Process,
        layout: "/admin",
        show: false,
    },
]

export const clientRoutes = [
    {
        path: "/client/dashboard",
        name: "Dashboard",
        description: "",
        icon: <DashboardIcon />,
        component: ClientDashboard,
        layout: "/client",
        show: true,
    },
    {
        path: "/client/search",
        name: "Pesquisar",
        description: "Busque seus documentos no KeepDoc",
        icon: <SearchIcon />,
        component: ClientSearch,
        layout: "/client",
        show: true,
    },
    {
        path: "/client/profile",
        name: "Meu perfil",
        description: "",
        icon: <UserIcon />,
        component: Profile,
        layout: "/client",
        show: true,
    },
    {
        path: "/client/process/:processid",
        component: Process,
        layout: "/client",
        show: false,
    },
]