import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const config = {
    apiKey: "AIzaSyCK7-jT5jkZYiBUgSxX1DoIgkKFbdSUKjM",
    authDomain: "keepdoc-bcdcc.firebaseapp.com",
    databaseURL: "https://keepdoc-bcdcc.firebaseio.com",
    projectId: "keepdoc-bcdcc",
    storageBucket: "keepdoc-bcdcc.appspot.com",
    messagingSenderId: "38383937368",
    appId: "1:38383937368:web:7cf3c1df786e8f146ca72d",
    // measurementId: "G-MEASUREMENT_ID",
  }

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}else {
  firebase.app(); // if already initialized, use that one
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const fieldValue = firebase.firestore.FieldValue
export const Timestamp = firebase.firestore.Timestamp

export const getUserDocument = async (user, token) => {

    if (!user) return

    const userRef = firestore.doc(`users/${user.uid}`)
    const snapshot = await userRef.get()

    if (!snapshot.exists) {
      return {
        uid: user.uid,
        token: token,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL
      }
    }

    return {
      uid: user.uid,
      token: token,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      ...snapshot.data()
    }

  }

export const getDocumentTypesList = () => {

  return firestore.collection("documentTypes").orderBy("name","asc")
    .get()

  }

export const getClientsList = () => {

  return firestore.collection("clients")
    .get()

  }

export const getClient = (id) => {

  return firestore.collection("clients").doc(id)
    .get()

  }

export const getClientProcesses = async (id,cursor=null,limit=50) => {
  let docsRef

  if(!cursor){

    docsRef = firestore.collection("processes").where("client","==",id).orderBy("date","desc").limit(limit)

  }else{

    docsRef = firestore.collection("processes").where("client","==",id).orderBy("date","desc").startAfter(cursor).limit(limit)

  }
  
  const snapshot = await docsRef.get()

  const last = snapshot.docs[snapshot.docs.length - 1]

  return {last,snapshot}

  }

export const getProcessById = (id) => {

  return firestore.collection("processes").doc(id)
    .get()

  }

export const saveProcess = (id,data) => {

  return firestore.collection("processes").doc(id)
  .set(data)

  }

export const updateProcess = (id,data) => {

  return firestore.collection("processes").doc(id)
  .update(data)

  }

export const updateDocument = (id,data) => {

  return firestore.collection("docs").doc(id)
  .update(data)

  }

export const getDocsByProcess = (id) => {

  let docsRef = firestore.collection("docs").where("process","==",id)

  return docsRef.get()

  }