import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import * as FirestoreService from '../../../firebase'
import {adminRoutes} from '../../../routes'
import { mapStateToProps, mapDispatchToProps } from '../../../store/functions/header'

const Clients = (props) => {
  const [clientsList, setClientsList] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    FirestoreService.getClientsList()
      .then(clientsList => {
        if(clientsList.docs){
          const result = clientsList.docs.map(doc => Object.assign({id: doc.id,name: doc.data().name || "Sem nome", email: doc.data().email || ""}) )

          setLoading(false)
          setClientsList(result)
        }
      })

      props.setTitle(adminRoutes[1].name)
      props.setDescription(adminRoutes[1].description)

  },[])

  const renderBody = () => {

    if(!loading){

      if(!clientsList){

        return (
          <Alert severity="info">Nenhum cliente cadastrado até o momento.</Alert>
        )

      }

      return (

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row"><Link href="#" variant="body2" onClick={(e) => { e.preventDefault();props.history.push(`/client/${row.id}`) }}>{row.name}</Link></TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      )

    }else{

      return (
        <LinearProgress size={24} color="secondary" />
      )

    }

  }

  return renderBody()

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Clients)