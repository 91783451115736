import React, { Component, createContext } from "react";
import { auth, getUserDocument } from '../firebase'

export const UserContext = createContext({ user: null })

class UserProvider extends Component {

    state = {
        user: null
    }

    componentDidMount = async () => {
        auth.onAuthStateChanged(async userAuth => {
            
            var token = ""

            if(userAuth){
                await userAuth.getIdToken().then(function(idToken) {
                    token = idToken
                });

                const user = await getUserDocument(userAuth,token)
                this.setState({ user })
            } else {
                this.setState({ user: null })
            }

        })
    }

    

    render() {
        return (
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        )
    }

}

export default UserProvider