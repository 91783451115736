import { updateHeaderTitle, updateHeaderDesc } from '../actions/header'

export function mapStateToProps(state) {
    return {
        title: state.header.title,
        description: state.header.description
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        setTitle(newTitle) {
            const action = updateHeaderTitle(newTitle)
            dispatch(action)
        },
        setDescription(newDescription) {
            const action = updateHeaderDesc(newDescription)
            dispatch(action)
        }
    }
}