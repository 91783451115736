import {
    HEADER_TITLE_CHANGED,
    HEADER_DESC_CHANGED
} from '../actions/actionsTypes'

const initalState = {
    title: "Dashboard",
    description: "",
}

export default function headerReducer(state = initalState, action) {

    switch(action.type) {
        case HEADER_TITLE_CHANGED:
            return {
                ...state,
                title: action.payload
            }
        case HEADER_DESC_CHANGED:
            return {
                ...state,
                description: action.payload
            }
        default:
            return state
    }

}